import { http } from '@/core/api';

const baseApiURL = 'api/message';

export const fetchApplication = () => {
  return http.get(`${baseApiURL}/applications`);
};
export const fetchLocation = (type = 'MessageLocation') => {
  return http.get(`${baseApiURL}/locations`, {
    params: {
      type,
    },
  });
};
export const fetchRol = () => {
  return http.get(`${baseApiURL}/roles`);
};
export const fetchMessages = (filter) => {
  return http.get(`${baseApiURL}/search`, {
    params: filter,
  });
};
export const fetchMessageById = (id) => {
  return http.get(`${baseApiURL}/${id}`);
};
export const deleteMessage = (id) => {
  return http.delete(`${baseApiURL}/delete/${id}`);
};
export const updateMessage = (data) => {
  return http.put(`${baseApiURL}/edit`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const saveMessage = (data) => {
  return http.post(`${baseApiURL}/save`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
