<template>
  <message-search
    :add-and-filter-persmission="canManageMessages"
    :default-application="parentAppId"
    :default-role="userProfile.roleId"
    :delete-message="deleteMessage"
    :download-file="downloadFile"
    :get-applications="getApplications"
    :get-locations="getLocations"
    :get-messages="getMessages"
    :get-roles="getRoles"
  />
</template>

<script>
//libs
import { MessageSearch } from 'operations';

//services
import {
  fetchMessages as _fetchMessages,
  fetchApplication as _fetchApplication,
  fetchRol as _fetchRol,
  fetchLocation as _fetchLocation,
  deleteMessage as _deleteMessage,
} from '@/api/message.api';
import generalService from '@/services/pei/general';

//constants
import applications from '@/utils/constants/applications.js';
import messageApi from '@/services/api/message.api';

export default {
  name: 'MainMessage',
  components: { MessageSearch },
  computed: {
    canManageMessages() {
      return this.userHasPermissions('operations.managemessages');
    },
    parentAppId() {
      return applications.Parent;
    },
  },
  async mounted() {
    await messageApi.resetMessageCount();
    this.$store.commit('setMessageCount', 0);
  },
  methods: {
    getMessages(params) {
      return _fetchMessages(params);
    },
    getApplications() {
      return _fetchApplication();
    },
    getRoles() {
      return _fetchRol();
    },
    getLocations() {
      return _fetchLocation();
    },
    deleteMessage(id) {
      return _deleteMessage(id);
    },
    downloadFile(documentId) {
      return generalService.downloadDocumentByAttachmentId(documentId);
    },
  },
};
</script>
